<template>
  <div class="metric-wrapper">
    <div class="metric-header">
      <div class="back-container">
        <svg-icon icon-class="back" class-name="back" @click.native="$router.go(-1)"></svg-icon>
        Edit Panel
      </div>

      <el-button type="primary" size="mini" @click="submit">提交</el-button>
    </div>

    <div v-loading="loading" class="metric-content-container">
      <el-row :gutter="20" v-if="!loading">
        <el-col :span="18">
          <div class="metric-chart-wrapper">
            <div class="list-title">{{ metricInfo.title }}</div>

            <chart-tool
              :visibleObj="{ timeRange: true, interval: false, addChart: false }"
              :timeRange.sync="timeRange"
              @timeRangeChange="timeRangeChange"
              class="chart-tool"
            />

            <div class="metric-chart-container">
              <chart-panel :timeRange="timeRange" ref="chart-panel" :metric-info="metricInfo" />
            </div>
          </div>

          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="Query" name="Query">
              <div class="promql-query-container">
                <div class="select-provider">
                  <span class="select-provider-text">选择Prometheus所属的集群：</span>

                  <el-select v-model="metricInfo.provider" size="small" @change="providerChange">
                    <el-option
                      v-for="item in providers"
                      :key="item.provideruuid"
                      :label="item.cluster"
                      :value="item.provideruuid"
                    >
                    </el-option>
                  </el-select>
                </div>

                <!-- <el-button type="primary" size="small" plain @click="dialogVisible = true">快速生成PromQL</el-button> -->
              </div>

              <query-item
                v-for="(item, index) in metricInfo.panelQLs"
                :data="{ item, index }"
                :values="metricsValueList"
                :key="index"
                @change="quertItemChange"
              ></query-item>

              <el-button size="small" icon="el-icon-plus" @click="addQueryItem">
                Add Query
              </el-button>
            </el-tab-pane>
          </el-tabs>
        </el-col>

        <el-col :span="6">
          <el-tabs type="card">
            <el-tab-pane label="面板设置">
              <el-collapse v-model="collapseName" class="chart-conf-collapse">
                <el-collapse-item title="标题设置" name="Setting">
                  <el-form size="small" label-position="top">
                    <el-form-item label="图表标题">
                      <el-input v-model="metricInfo.title"></el-input>
                    </el-form-item>

                    <el-form-item label="图表描述">
                      <el-input v-model="metricInfo.description" type="textarea"></el-input>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>

                <el-collapse-item title="类型设置" name="Visualization">
                  <el-row :gutter="20">
                    <el-col :span="8" v-for="item in visualizationList" :key="item.title" style="margin-bottom: 10px;">
                      <div
                        class="chart-type-item"
                        :class="{ active: item.title === metricInfo.chartType }"
                        @click="switchChartType(item.title)"
                      >
                        <div class="chart-type-item-title">{{ item.title }}</div>
                        <img :src="item.image" />
                      </div>
                    </el-col>
                  </el-row>
                </el-collapse-item>

                <el-collapse-item title="显示设置" name="Display">
                  <el-form :form="metricInfo.chartConfig" size="small" label-position="top">
                    <el-form-item label="Line 是否显示面积图">
                      <el-switch v-model="metricInfo.chartConfig.showArea"> </el-switch>
                    </el-form-item>

                    <!-- <el-form-item label="图例位置">
                      <el-radio-group v-model="metricInfo.chartConfig.legendPosition" size="small">
                        <el-radio-button label="bottom">Bottom</el-radio-button>
                        <el-radio-button label="right">Right</el-radio-button>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item label="警告值">
                      <el-input v-model.number="metricInfo.chartConfig.warningValue"> </el-input>
                    </el-form-item>

                    <el-form-item label="危险值">
                      <el-input v-model.number="metricInfo.chartConfig.dangerValue"> </el-input>
                    </el-form-item> -->
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane label="阈值设置">
              <el-collapse v-model="thresholdCollapseName" class="chart-conf-collapse">
                <el-collapse-item title="阈值" name="Threshold">
                  <el-form :form="metricInfo.chartConfig" size="small" label-position="top">
                    <el-form-item label="危险值">
                      <el-input v-model.number="metricInfo.chartConfig.dangerValue"> </el-input>
                    </el-form-item>
                  </el-form>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="PromQL生成器" :visible.sync="dialogVisible" top="30px">
      <el-form :model="qlForm" size="small" label-position="top">
        <el-form-item label="选择指标">
          <el-select v-model="qlForm.metric" style="width: 100%;" filterable>
            <el-option v-for="(item, index) in metricsValueList" :key="index" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="增加Label过滤条件">
          <edit-labels :data="qlForm.labels" ref="editLabelForm" />
        </el-form-item>

        <el-form-item label="函数表达式">
          <el-select v-model="qlForm.aggr" style="width: 100%;" filterable>
            <el-option value="sum">
              <div>
                <span style="float: left">sum</span>
                <span style="float: right; color: #8492a6; font-size: 12px">求和</span>
              </div>
            </el-option>

            <el-option value="count">
              <div>
                <span style="float: left">count</span>
                <span style="float: right; color: #8492a6; font-size: 12px">元素个数</span>
              </div>
            </el-option>

            <el-option value="rate">
              <div>
                <span style="float: left">rate</span>
                <span style="float: right; color: #8492a6; font-size: 12px">速率(性能好，适合长时间范围数据)</span>
              </div>
            </el-option>

            <el-option value="irate">
              <div>
                <span style="float: left">irate</span>
                <span style="float: right; color: #8492a6; font-size: 12px">速率(精度高，适合短时间范围数据)</span>
              </div>
            </el-option>

            <el-option value="increase">
              <div>
                <span style="float: left">increase</span>
                <span style="float: right; color: #8492a6; font-size: 12px">增长量</span>
              </div>
            </el-option>

            <el-option value="min">
              <div>
                <span style="float: left">min</span>
                <span style="float: right; color: #8492a6; font-size: 12px">最小值</span>
              </div>
            </el-option>

            <el-option value="max">
              <div>
                <span style="float: left">max</span>
                <span style="float: right; color: #8492a6; font-size: 12px">最大值</span>
              </div>
            </el-option>

            <el-option value="avg">
              <div>
                <span style="float: left">avg</span>
                <span style="float: right; color: #8492a6; font-size: 12px">平均值</span>
              </div>
            </el-option>

            <el-option value="topk">
              <div>
                <span style="float: left">topk</span>
                <span style="float: right; color: #8492a6; font-size: 12px">最大的 k 个元素</span>
              </div>
            </el-option>

            <el-option value="bottomk">
              <div>
                <span style="float: left">bottomk</span>
                <span style="float: right; color: #8492a6; font-size: 12px">最小的 k 个元素</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" size="small">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import QueryItem from "./QueryItem";
import ChartTool from "@/components/ChartTool";
import chartTool from "@/mixins/chart-tool";
import ChartPanel from "@/pages/Detail/components/ChartPanel";

import Bar from "@/assets/chart/Bar.svg";
import Text from "@/assets/chart/Text.svg";
import Line from "@/assets/chart/Line.svg";
// import Table from "@/assets/chart/Table.svg";

import { cloneDeep, merge } from "lodash";

import { createPanel, updatePanel, getPanel } from "api/dashboard";

import { CHART_CONFIG } from "utils/constant";

import { metricsValue } from "api/monitor";

export default {
  components: { QueryItem, ChartTool, ChartPanel },
  mixins: [chartTool],

  data() {
    return {
      visualizationList: [
        {
          title: "Line",
          image: Line
        },
        {
          title: "Bar",
          image: Bar
        },
        // {
        //   title: "Table",
        //   image: Table
        // },
        {
          title: "Text",
          image: Text
        }
      ],

      metricInfo: {
        organizationUUID: "",
        dashboardUUID: "",
        x: 0,
        y: 0,
        w: 6,
        h: 6,
        title: "Panel Title",
        description: "",
        chartType: "Line",
        chartConfig: CHART_CONFIG,
        provider: "",
        vendor: "",
        region: "",
        cluster: "",
        panelQLs: [
          {
            // query: "",
            query: "",
            // "kube_pod_container_resource_limits_cpu_cores{container='app-busybox',pod='apply-1-1611712800-zq28t',namespace='yang'}",
            // "sum (container_memory_working_set_bytes{container='',namespace='kube-system'}) by (namespace,pod) / 1024 / 1024",
            legend: "",
            queryType: ""
          }
        ]
      },

      activeName: "Query",
      collapseName: ["Setting", "Visualization", "Display"],
      thresholdCollapseName: ["Threshold"],
      loading: true,
      metricsValueList: [],
      dialogVisible: false,
      qlForm: {
        metric: "",
        labels: [],
        aggr: ""
      }
    };
  },

  methods: {
    timeRangeChange() {},

    switchChartType(type) {
      this.metricInfo.chartType = type;
    },

    quertItemChange(data) {
      let { item, index, type } = cloneDeep(data);
      type === "delete" ? this.metricInfo.panelQLs.splice(index, 1) : this.metricInfo.panelQLs.splice(index, 1, item);
    },

    addQueryItem() {
      this.metricInfo.panelQLs.push({
        query: "",
        legend: "",
        queryType: ""
      });
    },

    async getMetricsValue() {
      let response = await metricsValue(
        {
          vendor: this.metricInfo.vendor,
          region: this.metricInfo.region,
          cluster: this.metricInfo.cluster
        },
        {
          "X-Kubestar-Clusterids": this.metricInfo.provider
        }
      );

      if (response.code === 0) {
        let rep = JSON.parse(response.data);
        rep.status === "success" ? (this.metricsValueList = rep.data) : console.error(rep.error);
      }
    },

    providerChange(value) {
      for (let item of this.providers) {
        if (item.provideruuid === value) {
          this.metricInfo.vendor = item.kind;
          this.metricInfo.region = item.regionID;
          this.metricInfo.cluster = item.cluster;
          return;
        }
      }
    },

    getMetricInfo() {
      this.loading = true;
      console.log(this.dashboard, this.panel);

      getPanel(this.dashboard, this.panel).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.metricInfo = merge({}, { chartConfig: CHART_CONFIG }, cloneDeep(response.data));

          this.getMetricsValue();
        }
      });
    },

    submit() {
      let action = this.type === "add" ? createPanel : updatePanel;

      action(this.dashboard, this.metricInfo).then(response => {
        if (response.code === 0) {
          this.$router.push({
            path: `/dashboard-custom/dashboard/${this.dashboard}/detail`
          });
        }
      });
    }
  },

  mounted() {
    if (this.type === "add") {
      this.loading = false;
      this.metricInfo.provider = this.providers[0].provideruuid;
      this.metricInfo.vendor = this.providers[0].kind;
      this.metricInfo.region = this.providers[0].regionID;
      this.metricInfo.cluster = this.providers[0].cluster;
      this.metricInfo.dashboardUUID = this.dashboard;
      this.metricInfo.organizationUUID = this.organization;
      this.getMetricsValue();
    } else {
      this.getMetricInfo();
    }
  },

  computed: {
    type() {
      return this.$route.query.type;
    },

    providers() {
      return this.$store.state.app.userInfo.providers;
    },

    organization() {
      return this.$store.state.app.organization;
    },

    dashboard() {
      return this.$route.params.dashboard;
    },

    panel() {
      return this.$route.query.panel;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.promql-query-container {
  margin-bottom: 10px;

  @include flex(space-between);

  .select-provider {
    @include flex(flex-start);
    .select-provider-text {
      font-size: 14px;
    }
    .el-select {
      margin-right: 6px;
    }
  }
}

.metric-wrapper {
  padding: 0 10px;
  // background-color: #f2f5f8;
}

.metric-content-container {
  margin-top: 50px;
  padding: 20px;
}

.metric-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: $header-bg-color;
  @include flex(space-between);
  padding: 8px 20px;
  z-index: 1999;
  box-shadow: $common-shadow;
  color: #fff;

  .back-container {
    font-size: 14px;
    font-weight: 700;
    .svg-icon.back {
      width: 16px;
      height: 16px;
      margin-right: $grid-height;
      cursor: pointer;
    }
  }
}

.metric-chart-wrapper {
  height: 320px;
  background-color: #fff;
  border: 1px solid $border-color;
  box-shadow: $common-shadow;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;

  .list-title {
    height: 25px;
  }

  .chart-tool {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .metric-chart-container {
    height: calc(100% - 40px);
  }
}

.chart-type-item {
  text-align: center;
  padding: 8px;
  border: 1px solid $border-color;
  cursor: pointer;
  // background-color: #fafbfc;
  border-radius: 4px;
  // @include flex(flex-start);

  .chart-type-item-title {
    @include title(14px);
    margin-bottom: 10px;
  }

  &.active {
    border-color: $color-primary;
    border-width: 1px;
  }

  img {
    // width: 40px;
    // height: 30px;
    // margin-right: 20px;
    height: 40px;
  }

  &:hover {
    border-color: $color-primary;
  }
}

.chart-config-container {
  @include flex();
  margin-bottom: 10px;
}

.config-title {
  @include title(14px);
  margin-bottom: 10px;
}

.chart-tab.el-tabs--card {
  & > .el-tabs__header {
    // border-color: #c1c8cd;

    border-color: transparent;

    .el-tabs__nav {
      border: none;
    }

    .el-tabs__item {
      background-color: #cedae7;
      color: #fff;
      margin-right: 8px;
      border-radius: 4px 4px 0 0;
      padding: 0 30px;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;

      &.is-active {
        background-color: $color-primary;
      }
    }
  }
}

.chart-conf-collapse {
  .el-collapse-item__header {
    font-weight: 600;
  }
}
</style>
