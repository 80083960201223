<template>
  <div class="query-container" :class="{ opend: opend }">
    <div class="query-header">
      <i :class="opend ? 'el-icon-arrow-down' : 'el-icon-arrow-right'" @click="opend = !opend"></i>
      {{ data.index + 1 }} PromQL
    </div>

    <div class="query-content">
      <el-row :gutter="20">
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-autocomplete
            v-model="item.query"
            :fetch-suggestions="querySearch"
            highlight-first-item
            size="small"
            style="width: 100%;"
            @select="handleSelect"
            @change="change('update')"
          >
            <template slot="prepend">查询条件</template>
            <template slot-scope="{ item }">
              <div>{{ item }}</div>
            </template>
          </el-autocomplete>
        </el-col>

        <el-col :span="9">
          <el-input v-model="item.legend" size="small" @change="change('update')">
            <template slot="prepend">
              图例显示
              <el-tooltip
                effect="dark"
                :content="'使用 {{}} 设置图例显示的字段，例如使用 {{pod}} 显示POD的名称'"
                placement="bottom"
              >
                <span style="cursor: pointer;"><i class="el-icon-info"></i></span>
              </el-tooltip>
            </template>
          </el-input>
        </el-col>

        <el-col :span="6">
          <!-- <el-button size="small" icon="el-icon-data-line" @click="change('update')">更新</el-button> -->
          <el-button size="small" icon="el-icon-delete" @click="change('delete')">删除</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { cloneDeep, debounce, includes } from "lodash";

export default {
  props: {
    data: Object,
    values: Array
  },

  data() {
    return {
      opend: true,
      item: {}
    };
  },

  methods: {
    change(type) {
      this.$emit("change", {
        item: this.item,
        index: this.data.index,
        type
      });
    },

    handleSelect(item) {
      this.$set(this.item, "query", item);
      this.change("update");
    },

    querySearch(queryString, cb) {
      var results = queryString
        ? this.values.filter(item => {
            return includes(item, queryString);
          })
        : this.values;
      cb(results);
    }
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    }
  },

  mounted() {
    this.item = cloneDeep(this.data.item);
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.query-container {
  background-color: #fff;
  box-shadow: $common-shadow;
  border: 1px solid $border-color;
  margin-bottom: 10px;
  border-radius: 8px;

  &.opend {
    height: auto;
  }

  height: 40px;
  overflow: hidden;
}

.query-header {
  background-color: #fafbfc;
  padding: 4px 30px;
  color: $color-main;
  border-radius: 2px;
  line-height: 32px;
  font-size: 14px;

  position: relative;

  i {
    font-size: 16px;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.query-content {
  padding: 20px;

  .el-input-group--prepend .el-select.item-query-select .el-input.is-focus .el-input__inner {
    border-color: #409eff;
  }

  .item-query-select {
    width: 100%;
  }
}
</style>
